/**
 * App Light Theme
 */
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
   palette: {
		type: "light",
      primary: {
          main: '#0066CC' // azul
      },
      secondary: {
            main: '#218f72' //verde
        },
        error: {
            main:'#f44336'
        },
        warning: {
            main:'#ff9800'
        }
	}
	,overrides: {
		MuiTableRow:{
			root: {
                height: 48
            },
            MuiButton: {
                root: { 
                    textTransform: 'none'
                },
            },
		},
	},
});

export default theme;