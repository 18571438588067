import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import { Sync, Description } from '@material-ui/icons/';
import { CustomDataTable } from '../../components/UI/CustomDataTable';


import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import swal from 'sweetalert'
import store from '../../store';
import { CircularProgress } from '@material-ui/core';




export class Price extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],            
            selectedFile: null,
            isExecutingRequest: false,
            buttonDisabled: false,
            selectedFileEYD: null,

        };

        this.dataTableRef = React.createRef();
    } 

    onFileChange = event => {
        console.log("onFileChange");

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });


        this.setState({
            selectedFile: event.target.files[0] 
        }, () => {
            this.onFileUpload();
        });

    };

    onFileUpload = () => {

        console.log("onFileUpload");


        if (this.state.selectedFile != null) {

            const formData = new FormData();
            formData.append(
                "Image",
                this.state.selectedFile,
                this.state.selectedFile.name
            );


     

            axiosInstance.post('/api/product/uploadDiscounts', formData)
                .then(response => {

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });                   

            this.setState({
                selectedFile: null,
            })
        }
    };

    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '110px',
                hide: 'sm',
                cell: row =>

                    <div style={{ paddingTop: '8px' }}>
                        <b>{row.id}</b>
                        <Tooltip title="Artículo empresa Plataforma" placement="top">
                            <p style={{ color: '#666' }}> {row.codePlataforma} </p>
                          
                        </Tooltip>
                    </div>
                ,

            },

            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },


            {
                name: 'Precio PVP' ,
                selector: 'pvpPrice',
                sortable: true,
                hide: 'md',
                maxwidth: '90px',
                cell: row =>
                    
            <div style={{ paddingTop: '8px' }}>
                <b>$ {row.pvpPrice}</b>
                        <p style={{ color: '#666' }}> {row.pvpPriceUpdatedDate} </p>
            </div>
                ,
            },


            {
                name: 'Descuento',
                selector: 'biosintexDiscount',
                sortable: true,
                hide: 'md',
                maxwidth: '90px',
                cell: row =>
                    <div style={{ paddingTop: '8px' }}>
                        <Tooltip title="FCD" placement="top">
                            <b style={{ fontSize: '0.9rem', fontWeight: '800', color: '#ff7f27', marginLeft:'10px' }}>% {row.fcdDiscount}</b>
                        </Tooltip>

                        <Tooltip title="FAB" placement="top">
                            <b style={{ fontSize: '0.9rem', fontWeight: '800', color: '#6h5433', marginLeft: '12px' }}>% {row.fabDiscount}</b>
                        </Tooltip>


                        <Tooltip title="EYD" placement="top">
                            <b style={{ fontSize: '0.9rem', fontWeight: '800', color: '#666', marginLeft: '12px' }}>% {row.eydDiscount}</b>
                        </Tooltip>




                        <p style={{ color:'#ff7f27' }}> {row.fcdDiscountUpdatedDate} </p>
                        <p style={{ color: '#6h5433' }}> {row.fabDiscountUpdatedDate} </p>
                        <p style={{ color: '#666' }}> {row.eydDiscountUpdatedDate} </p>
                    </div>
                ,
            },

            {
                name: 'IVA',
                selector: 'tax',
                sortable: true,
                hide: 'md',
                width: '75px',
                cell: row =>
                    <p>% {row.tax}</p>
                ,
            },

            {
                name: 'Precio final(OpenLab)',
                selector: 'price',
                sortable: true,
                hide: 'md',
                mawidth: '90px',
                cell: row =>
                    <p style={{ fontSize:'0.9rem', fontWeight:'500' , color:'#0066CC'}}>$ {row.price}</p>
                ,
            },
          
       
        ];

        this.setState({
            columns: columns,                              
        });

      
    }

    syncPlataformaPVPPrices = async e => {

        this.setState({
            isExecutingRequest: true,
        });


        await axiosInstance.post(
            '/api/product/SyncPlataformaPVPPrices'
            , { headers: { "Access-Control-Allow-Origin": "*" } }
            
        )
            .then(response => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })
                this.setState({
                    isExecutingRequest: false,
                });

                this.dataTableRef.current.refreshDataTable();


            }).catch(error => {

                console.log("error: ", error);

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
                this.setState({
                    isExecutingRequest: false,
                });

            });

   
    }

    handleDeleteClick = async e =>{

 

        var id = e.currentTarget.dataset.row_id;
        var text = e.currentTarget.dataset.row_text;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: text,
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {
            await axiosInstance.delete('api/Offer/delete/' + id)
                .then(response => {

                    swal("Se eliminó el registro", {
                        icon: "success",
                    });
                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {

                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response.data.message
                    })

                });
        }
    }

    onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSubmit = e => {
        console.log("submiting");
        e.preventDefault();
    }

    render() {
      
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={8} lg={10}>

                        <h4>
                            Precios
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Precios y promos
                                </Typography>
                                    <Typography color="textPrimary">Precios</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
           

                        <input
                            //accept="image/*"
                            style={{display:'none'}}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={this.onFileChange}
                        />
                        <label htmlFor="contained-button-file">

                            <Button
                                startIcon={<Description />}
                                variant="contained"
                                component="span"
                                onClick={this.onFileUpload}
                            >
                                Subir archivo descuentos FCD y FAB
                            </Button>
                        </label>




                        <input
                            //accept="image/*"
                            style={{ display: 'none' }}
                            id="contained-button-file-EYD"
                            multiple
                            type="file"
                            onChange={this.onFileChangeEYD}
                        />
                        <label htmlFor="contained-button-file-EYD">

                            <Button
                                style={{ marginLeft: '12px' }}

                                startIcon={<Description />}
                                variant="contained"
                                component="span"
                                onClick={this.onFileUploadEYD}
                            >
                                Subir archivo descuentos EYD
                            </Button>
                        </label>

                     

                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: '12px' }}
                            onClick={this.syncPlataformaPVPPrices}
                            startIcon={this.state.isExecutingRequest ? <CircularProgress color="inherit" size="1.5rem" /> : <Sync />}
                            disabled={this.state.isExecutingRequest}

                        >
                            {this.state.isExecutingRequest ? "Sincronizando" : "Sincronizar precios PVP"}
                         
                        </Button>

                    </Grid>
                </Grid>

                <hr/>

                <CustomDataTable
                    url="api/Product/Prices"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }








    onFileChangeEYD = event => {
        console.log("onFileChangeEYD");

        // Update the state
        this.setState({ selectedFileEYD: event.target.files[0] });


        this.setState({
            selectedFileEYD: event.target.files[0]
        }, () => {
                this.onFileUploadEYD();
        });

    };

    onFileUploadEYD = () => {

        console.log("onFileUploadEYD");

        if (this.state.selectedFileEYD != null) {

            const formData = new FormData();
            formData.append(
                "Image",
                this.state.selectedFileEYD,
                this.state.selectedFileEYD.name
            );




            axiosInstance.post('/api/product/uploadDiscountsEYD', formData)
                .then(response => {

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });

            this.setState({
                selectedFileEYD: null,
            })
        }
    };
}
