import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import { Add, Edit, AddCircleOutline } from '@material-ui/icons/';
import { CustomDataTable } from '../../components/UI/CustomDataTable';

import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import store from '../../store';
import swal from 'sweetalert'



export class ReferredCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns:[],            
        };

        this.dataTableRef = React.createRef();
    } 
    
    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },

            {
                name: 'Fecha',
                selector: 'date',
                sortable: true,
            },
            {
                name: 'Razón social referente',
                selector: 'referUserName',
                sortable: true,
            },

            {
                name: 'Email referido',
                selector: 'email',
                sortable: true,
            },

            {
                name: 'Farmacia referida',
                selector: 'pharmacyName',
                sortable: true,
            },

            {
                name: 'Cuit referido',
                selector: 'cuit',
                sortable: true,
            }
          
             
         
        ];

        this.setState({
            columns: columns,                              
        });

      
    }

    

    render() {
      
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={7} lg={9}>
                        <h4>
                            Clientes referidos
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Biosintex24Hs
                                </Typography>
                                    <Typography color="textPrimary">   Clientes referidos</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={3}>
                        
                    </Grid>
                </Grid>

                <hr/>

                <CustomDataTable
                    url="api/ReferredCustomer"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }
}
