import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { axiosInstance } from '../../utils/axiosInstance';

import CancelIcon from '@material-ui/icons/Cancel';
import CastConnected from '@material-ui/icons/CastConnected';
import Send from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import XMLViewer from 'react-xml-viewer';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import store from '../../store';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export class OrderForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderID: 0,
            name: '',
            details: [],
            order: null,
            tabValue: '0',
            connection: null,
        };;

        this.orderID = this.props.match.params.value;

    }


    async sendOrderToPlataforma() {
        

        await axiosInstance.post(`api/Order/ResendOrderToPlataforma/${this.orderID}`)
            .then(response => {

                
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });
    }

    async checkPlataformaConnection() {
        await axiosInstance.get(`api/Order/CheckPlataformaConnection/`).then(
            response => {
                this.setState({
                    connection: response.data,
                });
            });
	}


    async componentDidMount() {

        await axiosInstance.get(`api/Order/GetByID/${this.orderID}`).then(
            response => {
                this.setState({
                    order: response.data,
                });
            });
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

    
            title ='Pedido';
    

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Pedido
                                        </Typography>


                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Pedidos">
                            <Button
                                variant="default"
                                color="text"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Volver
                        </Button>
                        </Link>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="default"
                    size="small"
                    startIcon={<Send />}
                    onClick={() => this.sendOrderToPlataforma()}
               
                >
                    Reenviar pedido
                </Button>

                <Button
                    variant="contained"
                    color="default"
                    size="small"
                    startIcon={<CastConnected />}
                    onClick={() => this.checkPlataformaConnection()}

                >
                    Chequear conexión Plataforma
                </Button>
                <hr />
            </div>
        );

        
    }

   
    handleTabChange = (event, newValue) => {
        this.setState({
            tabValue: newValue,
		})
    };

    

    render() {


        const { order } = this.state;


        return (

            <div>

                {this.HeaderTitle()}

                {this.state.order === null ? "" :


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h5>
                                    Pedido #{order.orderID}
                                    <br />
                                    <small style={{ fontSize: '0.9rem' }}>{order.date}</small>
                                    <br />
                                    <small>Estado: {order.state}</small>
                                    <br />
                                    <small>Droguería: {order.drugStore}</small>
                                </h5>

                                <hr />

                                <br />


                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={4} lg={4}>

                                        <h5>Cliente</h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                                Nombre: {order.clientName}
                                            </li>
                                            <li>
                                                Farmacia: {order.pharmacyName}
                                            </li>
                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                    </Grid>

                                   

                                </Grid>

                                <Grid container spacing={2}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Producto</TableCell>
                                                <TableCell align="right">Cantidad</TableCell>
                                                <TableCell align="right">SubTotal</TableCell>
                                                <TableCell align="right">Descuento</TableCell>
                                                <TableCell align="right">Ahorro extra</TableCell>
                                                <TableCell align="right">Total</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {order.details.map((row) => (
                                                <TableRow key={1}>
                                                    
                                                    <TableCell component="th" scope="row">
                                                        <img src={row.image} style={{ width: '90px' }} />
                                                        <b style={{marginLeft:'10px'}}>{row.product}</b>
                                                    </TableCell>
                                                    <TableCell align="right">{row.quantity}</TableCell>
                                                    <TableCell align="right">{row.subTotal}</TableCell>
                                                    <TableCell align="right">{row.save}</TableCell>
                                                    <TableCell align="right">{row.extraSave}</TableCell>
                                                    <TableCell align="right">{row.total}</TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"><strong style={{ float: 'right' }}>{order.subTotal}</strong></TableCell>
                                                <TableCell align="right">      <strong style={{ float: 'right' }}>{order.save} </strong></TableCell>
                                                <TableCell align="right">
                                                    <strong style={{ float: 'right' }}>{order.extraSave} </strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <h5 style={{ float: 'right' }}>{order.total}</h5>
                                                </TableCell>

                                            </TableRow>
                                        </TableFooter>
                                        
                                    </Table>
                                </Grid>

                             
                                <br/>
                                <br />
                           
                                <Grid container spacing={2}>
                                    <Grid item>
                                    <h5>Sincronización plataforma</h5>

                                    <br/>

                                    <Tabs
                                        value={this.state.tabValue}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={this.handleTabChange}
                                        aria-label="disabled tabs example"
                                        scrollButtons="on"
                                    >
                                        <Tab label="Status" value="0" />
                                        <Tab label="Request" value="1" />
                                        <Tab label="Response" value="2" />
                                    </Tabs>

                                    {this.state.tabValue == 0 ?

                                        <div>

                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Código</TableCell>
                                                        <TableCell align="left">Mensaje</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {order.webServiceResult.map((row) => (
                                                        <TableRow key={1}>

                                                            <TableCell component="th" scope="row">
                                                                <b style={{ marginLeft: '10px' }}>{row.code}</b>
                                                            </TableCell>
                                                            <TableCell align="left">{row.message}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        :

                                        null
                                    }


                                    {this.state.tabValue == 1 ?

                                        <div>

                                            {order.webServiceLog.map((row) => (

                                                <div>
                                                    <br/>
                                                    <h4>Request</h4>
                                                    <XMLViewer xml={row.request} />
                                            

                                                </div>

                                            ))}
                                        </div>
                                        :

                                        null
                                    }


                                    {this.state.tabValue == 2 ?

                                            <div>
                                                <br />

                                            {order.webServiceLog.map((row) => (

                                                <div>                                              
                                              
                                                    <h4>Response</h4>
                                                    <XMLViewer xml={row.response} />

                                                </div>

                                            ))}

                                        </div>
                                        :

                                        null
                                    }


                                </Grid>

                                </Grid>



                                <br/>
                                <br/>
                                <br />
                            </Paper>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}