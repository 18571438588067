import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import UserContext from '../userContext';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Divider } from '@material-ui/core';
import { deleteToken } from '../helpers/auth-helper';
import PersonIcon from '@material-ui/icons/Person';

const localVersion = localStorage.getItem('adminBiosintex');

export class LoginMenu extends Component {
	constructor(props) {
		super(props);
	}


	static contextType = UserContext;

	handleCloseSignOut = (e) => {

		const { logoutUser } = this.context;
		deleteToken(e);
		logoutUser();
	}

	render() {
		var { user } = this.context; 
		return (
			<div>
				{user.isAuth ?

					<div style={{ padding:'15px'}}>
						
						<center>

							<PersonIcon fontSize="large"/>
							<br/>

							<b>{user.name}</b><br/>
							<small style={{ color: '#666' }}>{user.email}</small>

							<br />

				

							<br />

						<Button

							color="secondary"
							startIcon={<ExitToApp />}
							size="small"
							onClick={this.handleCloseSignOut}
						>
							Cerrar sesión
						</Button>

							<br />
							<br />

							<small style={{ color: '#666' }}>{"v 0." + localVersion + ".13" }</small>

							</center>



					</div>
					: null}
				</div>
				);
	}
}